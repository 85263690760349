// src/components/TableauEmbed.js
//@ts-nocheck
import { useState, useEffect, useRef, useId } from 'react';
import { useLocation } from 'react-router-dom'

import { config } from 'config'
import { FilterUpdateType, TableauViz } from './TableauEmbeddedV3.js'
import { useAppDispatch, useAppSelector } from 'hooks'
import { updateTableauSession } from 'store/tableauSessionSlice'

type TableauProps = {
  tableauWorkbookName: string
  tableauDashboardName: string
  showHide?: number
  selectedFilter?: string
  token?: string | null
  isSessionDashboard?: boolean | null
}

const Tableau = (props: TableauProps) => {
  const {
    dateRange: { startDate, endDate },
    previous_start_date,
    previous_end_date,
    compare_with_year,
    previous_start_year,
    previous_end_year,
    business_purpose_filter,
    travel_type_filter,
    countries_filter,
    lob_filter
    // level_filter
  } = useAppSelector((state) => state.filters.filters)
  const { pathname } = useLocation()
  const id = useId()
  const GLRepotingAccess = useAppSelector((state) => state.userInfo.userAuthInfo?.gl_reporting)
  if(GLRepotingAccess =='Yes') {
    var isGLUser = true;
  }
  else var isGLUser = false;
  const [workbook, setWorkbook] = useState<any>(null)
  const [isloading, setLoading] = useState(true)
  const viz = new TableauViz();
  const ref = useRef<HTMLInputElement>(null)
  const dispatch = useAppDispatch()
  const isTableauSessionDashboardRendered = useAppSelector(
    (state) => state.tableauSession.isTableauSessionDashboardRendered
  )

  useEffect(() => {
    if (!workbook || props.isSessionDashboard) return
    let activeSheet = workbook.activeSheet

    if (activeSheet) {
      let vizSheet = activeSheet.worksheets[0]
      if (vizSheet) {
        // if (business_purpose_filter.length > 0) {
        //   let bpf: any = business_purpose_filter.map(function(x: any){ return x.value; })
        //   vizSheet.applyFilterAsync('Business_Purpose', [ ...bpf ], FilterUpdateType.Replace)
        // } else {
        //   vizSheet.applyFilterAsync('Business_Purpose', [ 'None' ], FilterUpdateType.Replace)
        // }
        if (business_purpose_filter === 'All') {
          vizSheet.clearFilterAsync('Business_Purpose')
        } else {
          vizSheet.applyFilterAsync('Business_Purpose', [business_purpose_filter], FilterUpdateType.Replace)
        }
      }
    }
  }, [
    business_purpose_filter
  ])

  useEffect(() => {
    if (!workbook || props.isSessionDashboard) return
    let activeSheet = workbook.activeSheet

    if (activeSheet) {
      let vizSheet = activeSheet.worksheets[0]
      if (vizSheet) {
        if (travel_type_filter === 'All') {
          vizSheet.clearFilterAsync('Dom_Int')
        } else {
          vizSheet.applyFilterAsync('Dom_Int', [travel_type_filter], FilterUpdateType.Replace)
        }
      }
    }
  }, [
    travel_type_filter
  ])

  useEffect(() => {
    if (!workbook || props.isSessionDashboard) return
    let activeSheet = workbook.activeSheet

    if (activeSheet) {
      let vizSheet = activeSheet.worksheets[0]
      if (vizSheet) {
        if (countries_filter.length !== 0) {
          vizSheet.applyFilterAsync('Country', countries_filter, FilterUpdateType.Replace)
        } else {
          vizSheet.applyFilterAsync('Country', [ 'None' ], FilterUpdateType.Replace)
        }
      }
    }
  }, [
    countries_filter
  ])

  useEffect(() => {
    if (!workbook || props.isSessionDashboard) return
    workbook.changeParameterValueAsync('P.Start_Date', startDate)
    workbook.changeParameterValueAsync('P.End_Date', endDate)
    workbook.changeParameterValueAsync('P.Previous_Start_Period', previous_start_date)
    workbook.changeParameterValueAsync('P.Previous_End_Period', previous_end_date)
    workbook.changeParameterValueAsync('P.Compare_With', compare_with_year)
    workbook.changeParameterValueAsync('P.Previous_Start_Year', previous_start_year)
    workbook.changeParameterValueAsync('P.Previous_End_Year', previous_end_year)
    workbook.changeParameterValueAsync('P.LOB', lob_filter.value)
    // workbook.changeParameterValueAsync('P.Level', level_filter.value)
  }, [
    startDate,
    endDate,
    previous_start_date,
    previous_end_date,
    compare_with_year,
    previous_start_year,
    previous_end_year,
    lob_filter.value
    // level_filter.value
  ])

  useEffect(() => {
    let vizUrl = `${config.TABLEAU_BASE_URL}views/${props.tableauWorkbookName}/${props.tableauDashboardName}?:origin=card_share_link&:embed=n&:refresh=yes`
    viz.src = vizUrl
    if (props.token) {
      viz.token = `${props.token}`
    }
    viz.hideTabs = true
    viz.toolbar = 'hidden'

    if(!props.isSessionDashboard && isTableauSessionDashboardRendered) {
      const parameter_start_date = document.createElement("viz-parameter");
      parameter_start_date.setAttribute("name", "P.Start_Date");
      parameter_start_date.setAttribute("value", startDate);
      parameter_start_date.setAttribute("data-type", "date");
      viz.appendChild(parameter_start_date);
  
      const parameter_end_date = document.createElement("viz-parameter");
      parameter_end_date.setAttribute("name", "P.End_Date");
      parameter_end_date.setAttribute("value", endDate);
      parameter_end_date.setAttribute("data-type", "date");
      viz.appendChild(parameter_end_date);

      const parameter_previous_start_date = document.createElement("viz-parameter");
      parameter_previous_start_date.setAttribute("name", "P.Previous_Start_Period");
      parameter_previous_start_date.setAttribute("value", previous_start_date);
      parameter_previous_start_date.setAttribute("data-type", "date");
      viz.appendChild(parameter_previous_start_date);
  
      const parameter_previous_end_date = document.createElement("viz-parameter");
      parameter_previous_end_date.setAttribute("name", "P.Previous_End_Period");
      parameter_previous_end_date.setAttribute("value", previous_end_date);
      parameter_previous_end_date.setAttribute("data-type", "date");
      viz.appendChild(parameter_previous_end_date);

      const parameter_compare_with_year = document.createElement("viz-parameter");
      parameter_compare_with_year.setAttribute("name", "P.Compare_With");
      parameter_compare_with_year.setAttribute("value", compare_with_year);
      parameter_compare_with_year.setAttribute("data-type", "int");
      viz.appendChild(parameter_compare_with_year);

      const parameter_previous_start_year = document.createElement("viz-parameter");
      parameter_previous_start_year.setAttribute("name", "P.Previous_Start_Year");
      parameter_previous_start_year.setAttribute("value", previous_start_year);
      parameter_previous_start_year.setAttribute("data-type", "date");
      viz.appendChild(parameter_previous_start_year);

      const parameter_previous_end_year = document.createElement("viz-parameter");
      parameter_previous_end_year.setAttribute("name", "P.Previous_End_Year");
      parameter_previous_end_year.setAttribute("value", previous_end_year);
      parameter_previous_end_year.setAttribute("data-type", "date");
      viz.appendChild(parameter_previous_end_year);

      const parameter_lob = document.createElement("viz-parameter");
      parameter_lob.setAttribute("name", "P.LOB");
      parameter_lob.setAttribute("value", lob_filter.value);
      parameter_lob.setAttribute("data-type", "str");
      viz.appendChild(parameter_lob);

      // const parameter_level = document.createElement("viz-parameter");
      // parameter_level.setAttribute("name", "P.Level");
      // parameter_level.setAttribute("value", level_filter.value);
      // parameter_level.setAttribute("data-type", "str");
      // viz.appendChild(parameter_level);

      const parameter_show_hide = document.createElement("viz-parameter");
      parameter_show_hide.setAttribute("name", "P.Show_Hide");
      parameter_show_hide.setAttribute("value", props.showHide);
      parameter_show_hide.setAttribute("data-type", "int");
      viz.appendChild(parameter_show_hide);

      if (props.selectedFilter) {
        viz.addFilter(
          'Dom_Int_Mod',
          [props.selectedFilter === 'All' ? ['Domestic', 'Intl Short Haul', 'Intl Long Haul'] : props.selectedFilter === 'All_Int' ? ['Intl Short Haul', 'Intl Long Haul'] : props.selectedFilter]
        )
      }

      // if (business_purpose_filter.length > 0) {
      //   let bpf: any = business_purpose_filter.map(function(x: any){ return x.value; })
      //   viz.addFilter(
      //     'Business_Purpose',
      //     [...bpf]
      //   )
      // } else {
      //   viz.addFilter(
      //     'Business_Purpose',
      //     ['None']
      //   )
      // }

      if (pathname.includes('digital-conferencing')) {
        viz.addFilter(
          'Business_Purpose',
          ['Non-Customer Facing']
        )
      } else {
        if (business_purpose_filter !== 'All') {
          viz.addFilter(
            'Business_Purpose',
            [business_purpose_filter]
          )
        }
      }

      if (travel_type_filter !== 'All') {
        viz.addFilter(
          'Dom_Int',
          [travel_type_filter]
        )
      }

      if (countries_filter.length > 0) {
        viz.addFilter(
          'Country',
          countries_filter
        )
      } else {
        viz.addFilter(
          'Country',
          ['None']
        )
      }
    }

    function firstInteractive() {
      setLoading(false)
      setWorkbook(viz.workbook)

      if (props.isSessionDashboard) {
        dispatch(
          updateTableauSession({
            isTableauSessionDashboardRendered: true,
          })
        )
      }
    }

    viz.addEventListener('firstinteractive', firstInteractive)

    if (!ref.current) {
      return
    }

    if (ref.current?.firstElementChild) {
      ref.current?.removeChild(ref.current?.firstElementChild)
    }

    if (props.isSessionDashboard || isTableauSessionDashboardRendered) {
      const element: HTMLElement = viz
      ref.current?.appendChild(element)

      // Add an event listener to detect changes in the container size 
      // const resizeListener = () => { 
      //   if (ref.current) { 
      //     viz.setFrameSize(ref.current.offsetWidth, ref.current.offsetHeight); 
      //   }
      // }; 
      
      // window.addEventListener('resize', resizeListener); 
      
      // // Remove the event listener when the component unmounts 
      // return () => { 
      //   window.removeEventListener('resize', resizeListener); 
      // };
    }

  }, [
    id,
    ref,
    props.token,
    props.tableauWorkbookName,
    props.tableauDashboardName,
    props.showHide,
    props.selectedFilter,
    props.isSessionDashboard,
    isTableauSessionDashboardRendered
  ]);

  return (
    <>
      {isloading && (
        <div className={`flex items-center justify-center ${(isGLUser && pathname.includes('gl-reporting')) ? 'h-[596px]' : 'h-[262px]'}`}>
            <div className='mr-[0.2rem] font-semibold text-gray-9 text-lg mb-[5px]'>Loading</div>
            <div class='h-[0.375rem] w-[0.375rem] mr-[0.125rem] bg-blue rounded-full animate-bounce [animation-delay:-0.3s]'></div>
            <div class='h-[0.375rem] w-[0.375rem] mr-[0.125rem] bg-blue rounded-full animate-bounce [animation-delay:-0.15s]'></div>
            <div class='h-[0.375rem] w-[0.375rem] bg-blue rounded-full animate-bounce'></div>
        </div>
      )}
      {(props.isSessionDashboard || isTableauSessionDashboardRendered) && ( 
        <div id="tableau-container" className="h-full">
          <div ref={ref} className={`${
            isloading ? 'invisible' : 'visible'
          } h-full flex justify-center`}></div>
        </div>
      )}
    </>
  );
};

export default Tableau;