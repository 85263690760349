//@ts-nocheck
import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'hooks'
import { useTable, usePagination } from 'react-table'
import { config } from 'config'
import Select, { components } from 'react-select'
import {
  format,
  endOfMonth,
  subMonths,
  getYear,
  getMonth,
  differenceInMonths,
  parse,
  subYears
} from 'date-fns';
import { updateFilters } from 'store/filtersSlice'
import { useDetectClickOutside } from 'react-detect-click-outside';
import StreamSaver from 'streamsaver';
import Scrollbar from 'react-scrollbars-custom'
import { 
  expensedwithzeroattendees_column_set, 
  overindividualcap_column_set, 
  overmultiattendeecap_column_set,  
  bookingoutsideagencydata_column_set, 
  cardatafile_column_set, 
  hoteldatafile_column_set, 
  airdata_cux_column_set, 
  airdata_lt14daysadv_column_set, 
  airdata_over100lostsavings_column_set,
  travelandexpensesummary_column_set, 
  travelandentertainmentspend_column_set,
  businessandfirstclasssegments_column_set,
  top100bycategory_column_set,
  top100bybusinesspurpose_column_set,
  summarybyorg_column_set,
  noncompliancesummary_column_set,
  outofpolicy_column_set,
  personaldailybookings_column_set,
  threeormoreviptravellersonsameflight_column_set,
  vipreport_column_set,
  octravelersnext21daysair_column_set,
  octravelersnext21dayshotel_column_set,
  airdirectionalreport_column_set,
  atickettotalsmonth_column_set,
  shortvslong_column_set,
  car_chain_spd_column_set,
  exchange_and_refund_column_set,
  airline_spend_column_set,
  airline_cont_saving_column_set,
  hotel_prop_rpt_column_set,
  trans_summary_column_set,
  ey_air_column_set,
  ey_rail_column_set,
  ey_hotel_column_set,
  airline_opt_column_set,
  hotel_chain_spend_column_set,
  glbl_trx_summary_column_set,
  segment_mil_column_set,
  top_trvl_seg_mil_qtr_column_set,
  top_trvl_seg_mil_yrly_column_set,
  glbl_air_spd_sumry_column_set
} from 'utils/columnsets'
import CountryFilter from 'components/options/CountryFilter';
import DatePickerSingle from 'components/options/DatePickerSingle';
import CalendarIcon from 'assets/images/calendar.svg'
import useFetchData from 'hooks/useFetchData';

const ReportingHubTable = () => {
  const [columns, setColumns] = useState(airdata_lt14daysadv_column_set);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [downloadStatus, setDownloadStatus] = useState('');
  const [cursor, setCursor] = useState('cursor-default');
  const userAuthInfo = useAppSelector((state) => state.userInfo.userAuthInfo)
  const tokenAuthInfo = useAppSelector((state) => state.userInfo)
  const {
    data: postTripInfoData,
    isLoading: isPostTripInfoLoading,
    isError: isPostTripInfoError,
  } = useFetchData("postTripInfo", "/auth/post-trip-info");
  const auth = {
    apikey: config.APIKEY,
    authorization: tokenAuthInfo?.tokens?.Authorization,
    authentication: tokenAuthInfo?.tokens?.Authentication,
    nonce: tokenAuthInfo?.tokens?.nonce
  }
  const {
    dateRange: { startDate, endDate },
    compare_with_year,
    business_purpose_filter,
    travel_type_filter,
    lob_filter
  } = useAppSelector((state) => state.filters.filters)
  const dispatch = useAppDispatch()
  const [selectedYear, setSelectedYear] = useState({ value: getYear(parse(startDate, 'yyyy-MM-dd', new Date())), label: getYear(parse(startDate, 'yyyy-MM-dd', new Date())) });

  let cutOffDay;

  if (userAuthInfo?.email.includes("amexgbt.com")) {
    cutOffDay = 14
  } else {
    cutOffDay = 19
  }

  const isAfterFeb19 = (new Date().getMonth() + 1) > 2 || ((new Date().getMonth() + 1) === 2 && (new Date().getDate()) > cutOffDay);

  let initialMonthOptions = [];
  let initialMonth;

  if (differenceInMonths(parse(endDate, 'yyyy-MM-dd', new Date()), parse(startDate, 'yyyy-MM-dd', new Date())) > 5) {
      initialMonth = { value: 'All', label: 'All' };
  } else {
      initialMonth = {
          value: (getMonth(parse(startDate, 'yyyy-MM-dd', new Date())) + 1).toString().padStart(2, '0'),
          label: format(parse(startDate, 'yyyy-MM-dd', new Date()), 'MMMM'),
      };
  }

  if (selectedYear.value === getYear(new Date())) {
      const currentMonth = new Date().getMonth() + 1;

      // Adjust the end month based on the current date
      const endMonth = new Date().getDate() > cutOffDay ? currentMonth - 1 : currentMonth - 2;

      for (let index = 1; index <= endMonth; index++) {
        const monthValue = index;
        const monthLabel = new Date(2022, index - 1, 1).toLocaleString('default', { month: 'long' });
        initialMonthOptions.push({ value: monthValue, label: monthLabel });
      }

      initialMonthOptions.unshift({ value: 'All', label: 'All' });
      if (!initialMonthOptions.some(el => el.value === initialMonth.value)) {
        initialMonth = initialMonthOptions[initialMonthOptions.length - 1]
      }
  } else {
    initialMonthOptions = [
        { value: 'All', label: 'All' },
        { value: '01', label: 'January' },
        { value: '02', label: 'February' },
        { value: '03', label: 'March' },
        { value: '04', label: 'April' },
        { value: '05', label: 'May' },
        { value: '06', label: 'June' },
        { value: '07', label: 'July' },
        { value: '08', label: 'August' },
        { value: '09', label: 'September' },
        { value: '10', label: 'October' },
        { value: '11', label: 'November' },
        { value: '12', label: 'December' },
      ];
  }

  const [ monthOptions, setMonthOptions ] = useState(initialMonthOptions)
  const [ selectedMonth, setSelectedMonth ] = useState(initialMonth);
  const [ selectedLob, setSelectedLob ] = useState(lob_filter)
  const [ weeks, setWeeks ] = useState([]);

  const initialLobInfo = userAuthInfo?.lob_info;

  const reportCategories = [
    { value: 'LOB', label: 'LOB' },
    // { value: 'Compliance', label: 'Compliance' },
    // { value: 'Air', label: 'Air' },
    // { value: 'Travel Summary', label: 'Travel Summary' }
    // { value: 'Outbound', label: 'Outbound' }
  ]

  if(userAuthInfo?.email.includes("amexgbt.com") || postTripInfoData?.post_trip_info.includes(userAuthInfo?.email)) {
    reportCategories.push({ value: 'Post Trip', label: 'Post Trip' })
  }

  if (initialLobInfo.some(item => ['WFC', 'WIM', 'COMMBKG', 'CORPINVBKG'].includes(item.bu_short_code))) {
    reportCategories.push({ value: 'LOB-Org Unit Compare', label: 'LOB-Org Unit Compare' })
  }

  let reports = [
    { value: 'Meals - Expensed with Zero Attendees', label: 'Meals - Expensed with Zero Attendees' },
    { value: 'Meals - Over Individual Cap', label: 'Meals - Over Individual Cap' },
    { value: 'Meals - Over Multi Attendee Cap', label: 'Meals - Over Multi Attendee Cap' },
    { value: 'Top 50 trending Individual Meal Offenders', label: 'Top 50 trending Individual Meal Offenders' },
    { value: 'Top 50 trending Multi-Attendee Meals Offenders', label: 'Top 50 trending Multi-Attendee Meals Offenders' },
    { value: 'Booking Outside Agency Data', label: 'Booking Outside Agency Data' },
    { value: 'Car Data File', label: 'Car Data File' },
    { value: 'Hotel Data File', label: 'Hotel Data File' },
    { value: 'Air Data File - Non-Customer Facing', label: 'Air Data File - Non-Customer Facing' },
    { value: 'Air Data File - Less Than 14 Days Adv', label: 'Air Data File - Less Than 14 Days Adv' },
    { value: 'Air Data File - Over 100 Lost Savings', label: 'Air Data File - Over 100 Lost Savings' },
    { value: 'Top 50 Hotel Offenders', label: 'Top 50 Hotel Offenders' },
    { value: 'Top 50 Air Offenders', label: 'Top 50 Air Offenders' },
    { value: 'Travel and Expense Summary', label: 'Travel and Expense Summary' },
    { value: 'Top 50 TM Travel and Entertainment Spend', label: 'Top 50 TM Travel and Entertainment Spend' },
    { value: 'Top 200 TM Travel and Entertainment Spend', label: 'Top 200 TM Travel and Entertainment Spend' },
    { value: 'All TM Travel and Entertainment Spend', label: 'All TM Travel and Entertainment Spend' },
    { value: 'Business and First Class Segments', label: 'Business and First Class Segments' },
    { value: 'Top 100 by Category', label: 'Top 100 by Category' },
    { value: 'Top 100 by Business Purpose', label: 'Top 100 by Business Purpose' },
  ].sort((a, b) => a.label.localeCompare(b.label))

  const [ selectedReportCategory, setSelectedReportCategory ] = useState(reportCategories[0])
  const [ selectedReports, setSelectedReports ] = useState(reports)
  const [ selectedReport, setSelectedReport ] = useState(reports[0])
  // const [ businessPurposeFilter, setBusinessPurposeFilter ] = useState([
  //   { value: 'Customer Facing', label: 'Customer Facing' },
  //   { value: 'Non-Customer Facing', label: 'Non-Customer Facing' },
  //   { value: 'Personal', label: 'Personal' }
  // ])
  const [ businessPurposeFilter, setBusinessPurposeFilter ] = useState(business_purpose_filter)
  const [ businessPurposeFilters, setBusinessPurposeFilters ] = useState([
    { value: 'All', label: 'All' },
    { value: 'Customer Facing', label: 'Customer Facing' },
    { value: 'Non-Customer Facing', label: 'Non-Customer Facing' },
    // { value: 'Personal', label: 'Personal' }
  ])
  const [ travelTypeFilter, setTravelTypeFilter ] = useState(travel_type_filter)
  const [ regions, setRegions ] = useState(userAuthInfo?.regions.map((region) => ({
      checked: true,
      label: region.region,
      value: region.region,
      children: region.countries.map((country) => ({
          label: country,
          value: country,
      })),
  })))
  const [open, setOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(format(
    new Date(),
    "yyyy-MM-dd"
  ))

  let customItems = [];

  if (selectedReportCategory.value === 'LOB-Org Unit Compare') {
    customItems = initialLobInfo.map((item) => {
      if (['WFC', 'WIM', 'COMMBKG', 'CORPINVBKG'].includes(item.bu_short_code)) {
        return {
          value: `-${item.bu_short_code}-`,
          label: `${item.bu_short_code} - ${item.bu_long_name} (Level ${item.report_level})`
        };
      }
      // If the condition is not met, return null or an empty object, depending on your preference.
      return null;
    }).filter(Boolean);
  } else {
    customItems = initialLobInfo.map((item) => ({
      value: `-${item.bu_short_code}-`,
      label: `${item.bu_short_code} - ${item.bu_long_name} (Level ${item.report_level})`
    }));

    if (userAuthInfo?.role === 'super_user') {
      customItems = [...customItems, {
        value: `Default`,
        label: `Default`
      }]
    }
  }

  const selectedCountries = [] 

  const travelTypeFilters = [
      { value: 'All', label: 'All' },
      { value: 'Domestic', label: 'Domestic' },
      { value: 'International', label: 'International' }
  ];

  function handleClick() {
    return setOpen(!open)
  }

  function closeDatePicker() {
    return setOpen(false)
  }

  const outsideClickref = useDetectClickOutside({
    onTriggered: closeDatePicker,
  })

  useEffect(() => {
    if (selectedReport.value === 'Air Data File - Non-Customer Facing') {
      setBusinessPurposeFilter('Non-Customer Facing')
      setBusinessPurposeFilters([
        { value: 'Non-Customer Facing', label: 'Non-Customer Facing' }
      ])
    } else {
      setBusinessPurposeFilters([
        { value: 'All', label: 'All' },
        { value: 'Customer Facing', label: 'Customer Facing' },
        { value: 'Non-Customer Facing', label: 'Non-Customer Facing' },
        // { value: 'Personal', label: 'Personal' }
      ])
    }
    if (
      [
        'Top 50 trending Individual Meal Offenders', 
        'Top 50 trending Multi-Attendee Meals Offenders',
        'Top 50 Hotel Offenders',
        'Top 50 Air Offenders',
        'Global Hotel Preference Reasons',
        'Global Hotel Summary',
        'Advanced Purchase YOY',
        'YOY ATP',
        'Global Air Savings Opportunities',
        'Hotel Property Report',
        'Top 10 Non-Directional Markets by Spend'
      ].includes(selectedReport.value)
    ) {
      if (monthOptions.some(e => e.value === 'All')) {
        setMonthOptions(monthOptions.filter((e) => e.value !== 'All'))
      }
    } else {
      setMonthOptions(initialMonthOptions);
    }
  }, [selectedReport])

  useEffect(() => {
    // Function to get the current week number
    const getWeekNumber = (date) => {
      const today = new Date(date);
      const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
      const pastDaysOfYear = (today - firstDayOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };

    // Function to generate options for weeks
    const generateWeekOptions = () => {
      const totalWeeks = 53;  // Total number of weeks in a year
      const currentWeek = getWeekNumber(new Date());

      const options = [];
      for (let week = 1; week <= totalWeeks; week++) {
        if (week <= currentWeek) {
          options.push({ value: week, label: `Week ${week}` });
        }
      }
      return options;
    };

    const weekOptions = generateWeekOptions();
    setWeeks(weekOptions);
  }, []);

  useEffect(() => {
    if (selectedReportCategory.value === 'LOB') {
      setSelectedReports(reports)
    } else if (selectedReportCategory.value === 'Compliance') {
      setSelectedReports([
        { value: 'Out of Policy', label: 'Out of Policy' }
      ])
    } else if (selectedReportCategory.value === 'Air') {
      setSelectedReports([
        { value: 'Personal Daily Bookings', label: 'Personal Daily Bookings' },
        { value: 'Three or More VIP Travellers on same flight', label: 'Three or More VIP Travellers on same flight' }
      ])
    } else if (selectedReportCategory.value === 'Travel Summary') {
      setSelectedReports([
        { value: 'VIP Report', label: 'VIP Report' },
        { value: 'OC Travelers Next 21 days (Air)', label: 'OC Travelers Next 21 days (Air)' },
        { value: 'OC Travelers Next 21 days (Hotel)', label: 'OC Travelers Next 21 days (Hotel)' },
      ])
    } else if (selectedReportCategory.value === 'LOB-Org Unit Compare') {
      setSelectedReports([
        { value: 'Summary by Org', label: 'Summary by Org' },
        { value: 'Non Compliance Summary', label: 'Non Compliance Summary' },
      ])
      setSelectedLob(customItems[0])
    } else if (selectedReportCategory.value === 'Post Trip') {
      setSelectedReports([
        { value: 'Advanced Purchase YOY', label: 'Advanced Purchase YOY' },
        { value: 'Air Directional Market Report - 3mth', label: 'Air Directional Market Report - 3mth' },
        { value: 'Airline Contract Savings', label: 'Airline Contract Savings' },
        { value: 'Airline Optimization', label: 'Airline Optimization' },
        { value: 'Airline Spend', label: 'Airline Spend' },
        { value: 'Car Chain Spend', label: 'Car Chain Spend' },
        { value: 'Ernst And Young Reports Air', label: 'Ernst & Young Reports Air' },
        { value: 'Ernst And Young Reports Hotel', label: 'Ernst & Young Reports Hotel' },
        { value: 'Ernst And Young Reports Rail', label: 'Ernst & Young Reports Rail' },
        { value: 'Exchanges and Refunds', label: 'Exchanges and Refunds' },
        { value: 'Global Air Savings Opportunities', label: 'Global Air Savings Opportunities' },
        { value: 'Global Air Spend Summary', label: 'Global Air Spend Summary' },
        { value: 'Global Hotel Preference Reasons', label: 'Global Hotel Preference Reasons' },
        { value: 'Global Hotel Summary', label: 'Global Hotel Summary' },
        { value: 'Global Transaction Summary', label: 'Global Transaction Summary' },
        { value: 'Hotel Chain Spend', label: 'Hotel Chain Spend' },
        { value: 'Hotel Property Report', label: 'Hotel Property Report' },
        { value: 'NCF Report Short vs. Long Trip', label: 'NCF Report Short vs. Long Trip' },
        { value: 'Segment Mileage', label: 'Segment Mileage' },
        { value: 'Ticket Totals by Month', label: 'Ticket Totals by Month' },
        { value: 'Top 10 Non-Directional Markets by Spend', label: 'Top 10 Non-Directional Markets by Spend' },
        { value: 'Top Traveler with Segments and Miles Quarterly', label: 'Top Traveler with Segments and Miles Quarterly' },
        { value: 'Top Traveler with Segments and Miles Yearly', label: 'Top Traveler with Segments and Miles Yearly' },
        { value: 'Transaction Summary Detail', label: 'Transaction Summary Detail' },
        { value: 'YOY ATP', label: 'YOY ATP' },
      ])
    } else {
      setSelectedReports([
        { value: 'CTE AU (Masked Credit Card)', label: 'CTE AU (Masked Credit Card)' }
      ])
    }
  }, [selectedReportCategory.value])

  useEffect(() => {
    setSelectedReport(selectedReports[0])
  }, [selectedReports])

  const currentYear = new Date().getFullYear();
  const startingYear = 2019; // You can adjust this to your preferred starting year
  const yearOptions = [];

  for (let year = startingYear; year <= currentYear; year++) {
    if (year >= 2023) {
      yearOptions.push({ value: year, label: `${year}` });
    }
  }

  if (!isAfterFeb19) {
    yearOptions.pop()
  }

  const InputOption = (props) => {
      return (
          <div>
              <components.Option {...props}>
                  <input
                  type="checkbox"
                  checked={props.isSelected}
                  onChange={() => null}
                  />{" "}
                  <label>{props.label}</label>
              </components.Option>
          </div>
      );
  };

  const fetchTableData = async (pageIndex, pageSize) => {
    regions.forEach((region) => {
        region.children.forEach((country) => {
            if (region.checked === true || country.checked === true) {
                selectedCountries.push(country.value.toUpperCase())
            }   
        })
    })
    setDownloadStatus('');
    try {
      let url = ''
      if (selectedReportCategory.value === 'LOB') {
        if (!['Top 100 by Category', 'Top 100 by Business Purpose'].includes(selectedReport.value)) {
          url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/?offset=${pageIndex * pageSize}&limit=${pageSize}&report_name=${selectedReport.value}&lob_name=${selectedLob.value}&business_purpose=${businessPurposeFilter}&travel_type=${travelTypeFilter}&countries=${selectedCountries}&year=${selectedYear.value}&month=${selectedMonth.value}`
        } else {
          url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/?offset=${pageIndex * pageSize}&limit=${pageSize}&report_name=${selectedReport.value}&lob_name=${selectedLob.value}&countries=${selectedCountries}&year=${selectedYear.value}&month=${selectedMonth.value}`
        }
        // url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/?offset=${pageIndex * pageSize}&limit=${pageSize}&report_name=${selectedReport.value}&lob_name=${selectedLob.value}&business_purpose=${businessPurposeFilter.map(function(x: any){ return x.value; })}&travel_type=${travelTypeFilter}&countries=${selectedCountries}&year=${selectedYear.value}&month=${selectedMonth.value}`
      } else if (selectedReportCategory.value === 'LOB-Org Unit Compare') {
        url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/?offset=${pageIndex * pageSize}&limit=${pageSize}&report_name=${selectedReport.value}&lob_name=${selectedLob.value}&countries=${selectedCountries}&year=${selectedYear.value}&month=${selectedMonth.value}`
      } else if (selectedReportCategory.value === 'Post Trip') {
          if (['Global Hotel Preference Reasons','Global Hotel Summary'].includes(selectedReport.value)) {
            url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/?offset=${pageIndex * pageSize}&limit=${pageSize}&report_name=${selectedReport.value}&travel_type=${travelTypeFilter}&year=${selectedYear.value}&month=${selectedMonth.value}`
          } else if (['Ernst And Young Reports Air','Ernst And Young Reports Rail','Ernst And Young Reports Hotel', 'Global Air Spend Summary'].includes(selectedReport.value)) {
            url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/?offset=${pageIndex * pageSize}&limit=${pageSize}&report_name=${selectedReport.value}&year=${selectedYear.value}&month=${selectedMonth.value}`
          } else if (['Segment Mileage'].includes(selectedReport.value)) {
            url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/?offset=${pageIndex * pageSize}&limit=${pageSize}&report_name=${selectedReport.value}&countries=${selectedCountries}&year=${selectedYear.value}&month=${selectedMonth.value}`
          } else {
            url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/?offset=${pageIndex * pageSize}&limit=${pageSize}&report_name=${selectedReport.value}&travel_type=${travelTypeFilter}&countries=${selectedCountries}&year=${selectedYear.value}&month=${selectedMonth.value}`
          }
      } else {
        url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/?offset=${pageIndex * pageSize}&limit=${pageSize}&report_name=${selectedReport.value}&countries=${selectedCountries}&date=${selectedDate}`
      }
      const response = await fetch(url, {
        headers: {
          apikey: config.APIKEY,
          Authorization: auth.authorization,
          Authentication: auth.authentication,
          nonce: auth.nonce,
        }
      });
      const result = await response.json();
      setData(result.data);
      setTotalCount(result.total_count);
      setLoading(false);
    } catch {
      setData([]);
      setTotalCount(1);
      setLoading(false);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 50 }, // initial page index and size
      manualPagination: true,
      pageCount: totalCount
    },
    usePagination
  );

  useEffect(() => {
    setLoading(true);
    fetchTableData(pageIndex, pageSize);
  }, [pageIndex, pageSize]);

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    setPageSize(newSize);
    gotoPage(0);
  };

  const updateFiltersFunc = () => {
    let selectedStartMonth = selectedMonth.value
    let selectedEndMonth = selectedMonth.value

    if(selectedMonth.value === "All") {
        selectedStartMonth = "01"
        selectedEndMonth = monthOptions[monthOptions.length - 1].value
    }

    const startDate = format(
        parse(`${selectedYear.value}-${selectedStartMonth}-01`, 'yyyy-MM-dd', new Date()), 
        'yyyy-MM-dd'
    )
    const endDate = format(
        endOfMonth(
            parse(`${selectedYear.value}-${selectedEndMonth}-01`, 'yyyy-MM-dd', new Date())
        ), 
        'yyyy-MM-dd'
    )

    let previousStartDate;
    let previousEndDate;

    if(selectedMonth.value === "All") {
        previousStartDate = format(
            subYears(
                parse(startDate, 'yyyy-MM-dd', new Date()),
                1 
            ), 
            'yyyy-MM-dd'
        )
        previousEndDate = format(
            endOfMonth(
                subYears(
                    parse(endDate, 'yyyy-MM-dd', new Date()),
                    1 
                )
            ), 
            'yyyy-MM-dd'
        )
    } else {
        previousStartDate = format(
            subMonths(
                parse(startDate, 'yyyy-MM-dd', new Date()),
                1 
            ), 
            'yyyy-MM-dd'
        )
        previousEndDate = format(
            endOfMonth(
                subMonths(
                    parse(endDate, 'yyyy-MM-dd', new Date()),
                    1 
                )
            ), 
            'yyyy-MM-dd'
        )
    }

    const previousStartYear = format(
        parse(startDate, 'yyyy-MM-dd', new Date()), 
        `${compare_with_year}-MM-dd`
    )
    const previousEndYear = format(
      endOfMonth(
          parse(format(
              parse(startDate, `yyyy-MM-dd`, new Date()),
              `${compare_with_year}-MM-dd`
          ), 'yyyy-MM-dd', new Date()),
      ),
      "yyyy-MM-dd"
    );

    dispatch(
      updateFilters({
            dateRange: {
                startDate: startDate,
                endDate: endDate,
            },
            previous_start_date: previousStartDate,
            previous_end_date: previousEndDate,
            previous_start_year: previousStartYear,
            previous_end_year: previousEndYear,
            business_purpose_filter: businessPurposeFilter,
            travel_type_filter: travelTypeFilter,
            countries_filter: selectedCountries,
            lob_filter: selectedLob
        })
    );

    console.log("startdate ", startDate)
    console.log("endDate ", endDate)
    console.log("previousStartDate ", previousStartDate)
    console.log("previousEndDate ", previousEndDate)
    console.log("selectedComparisonYear ", compare_with_year)
    console.log("previousStartYear ", previousStartYear)
    console.log("previousEndYear ", previousEndYear)
    console.log("businessPurposeFilter ", businessPurposeFilter)
    console.log("travelTypeFilter ", travelTypeFilter)
    console.log("selectedCountries ", selectedCountries)
    console.log("selectedLob ", selectedLob)
  }

  const previewReport = async () => {
    setLoading(true);
    fetchTableData(0, pageSize);
    updateFiltersFunc();
    // Calculate the start month based on the target month and subtract 5 months
    const startDate = new Date(selectedYear.value, selectedMonth.value - 1, 1);
    startDate.setMonth(startDate.getMonth() - 5);
    const startMonth = startDate.getMonth() + 1;
    const startYear = startDate.getFullYear();

    // Generate the list of months from the start month to the target month
    const months = Array.from({ length: 6 }, (_, i) => {
      const month = (startMonth + i - 1) % 12 + 1;
      const year = month > selectedMonth.value ? startYear : selectedYear.value;
      return { year, month };
    });

    // Function to generate month quarter
    function getQuarterStartingMonth(month) {
      return Math.floor((month - 1) / 3) * 3 + 1;
    }

    // Define a function to convert month numbers to month names
    const getMonthName = (monthNum) => new Date(2000, monthNum - 1, 1).toLocaleString('en-us', { month: 'short' });
    if (selectedReport.value === 'Meals - Expensed with Zero Attendees') {
      setColumns(expensedwithzeroattendees_column_set)
    } else if (selectedReport.value === 'Meals - Over Individual Cap') {
      setColumns(overindividualcap_column_set)
    } else if (selectedReport.value === 'Meals - Over Multi Attendee Cap') {
      setColumns(overmultiattendeecap_column_set)
    } else if ([
      'Top 50 trending Individual Meal Offenders',
      'Top 50 trending Multi-Attendee Meals Offenders'
    ].includes(selectedReport.value)) {
      let columns = []
      // Create an array to store the generated columns
      if (selectedReport.value === 'Top 50 trending Individual Meal Offenders')
        columns = [
          { Header: 'EID', accessor: 'eid' },
          { Header: 'Employee', accessor: 'employee' },
          { Header: 'Title', accessor: 'job title' },
          { Header: 'Email Address', accessor: 'email' },
          { Header: 'AU', accessor: 'au' }
        ];
      else {
        columns = [
          { Header: 'EID', accessor: 'eid' },
          { Header: 'Employee', accessor: 'employee' },
          { Header: 'Job Title', accessor: 'job title' },
          { Header: 'Email', accessor: 'email' },
          { Header: 'Charged AU', accessor: 'au' }
        ];
      }

      // Iterate through the months and generate the columns
      months.forEach(({ year, month }) => {
        const monthName = getMonthName(month);
        columns.push({
          Header: `Amount Over Cap ${monthName}-${year}`,
          accessor: `amount over cap ${monthName.toLowerCase()}-${year}`,
        });
        columns.push({
          Header: `Entries Over Cap ${monthName}-${year}`,
          accessor: `entries over cap ${monthName.toLowerCase()}-${year}`,
        });
      });

      columns.push({
        Header: `6 Month Total Amount Over Cap`,
        accessor: `6 month total amount over cap`,
      });
      columns.push({
        Header: `6 Month Total Entries Over Cap`,
        accessor: `6 month total entries over cap`,
      });

      setColumns(columns)
    } else if (selectedReport.value === 'Booking Outside Agency Data') {
      setColumns(bookingoutsideagencydata_column_set)
    } else if (selectedReport.value === 'Car Data File') {
      setColumns(cardatafile_column_set)
    } else if (selectedReport.value === 'Hotel Data File') {
      setColumns(hoteldatafile_column_set)
    } else if (selectedReport.value === 'Air Data File - Non-Customer Facing') {
      setColumns(airdata_cux_column_set)
    } else if (selectedReport.value === 'Air Data File - Less Than 14 Days Adv') {
      setColumns(airdata_lt14daysadv_column_set)
    } else if (selectedReport.value === 'Air Data File - Over 100 Lost Savings') {
      setColumns(airdata_over100lostsavings_column_set)
    } else if (selectedReport.value === 'Top 50 Hotel Offenders') {
      // Create an array to store the generated columns
      const columns = [
        { Header: 'EID', accessor: 'eid' },
        { Header: 'Traveler', accessor: 'traveler' },
        { Header: 'Job Title', accessor: 'job title' },
        { Header: 'Email', accessor: 'email' },
        { Header: 'AU', accessor: 'au' },
      ];

      // Iterate through the months and generate the columns
      months.forEach(({ year, month }) => {
        const monthName = getMonthName(month);
        columns.push({
          Header: `Hotel Savings Opportunity ${monthName}-${year}`,
          accessor: `hotel savings opportunity ${monthName.toLowerCase()}-${year}`,
        });
        columns.push({
          Header: `Hotel Nights Over Cap ${monthName}-${year}`,
          accessor: `hotel nights over cap ${monthName.toLowerCase()}-${year}`,
        });
      });

      columns.push({
        Header: `6 Month Total Hotel Savings Opportunity`,
        accessor: `6 month total hotel savings opportunity`,
      });
      columns.push({
        Header: `6 Month Total Hotel Nights Over Cap`,
        accessor: `6 month total hotel nights over cap`,
      });

      setColumns(columns)
    } else if (selectedReport.value === 'Top 50 Air Offenders') {
      // Create an array to store the generated columns
      const columns = [
        { Header: 'EID', accessor: 'eid' },
        { Header: 'Traveler', accessor: 'traveler' },
        { Header: 'Job Title', accessor: 'job title' },
        { Header: 'Email', accessor: 'email' },
        { Header: 'AU', accessor: 'au' },
      ];

      // Iterate through the months and generate the columns
      months.forEach(({ year, month }) => {
        const monthName = getMonthName(month);
        columns.push({
          Header: `Savings Opportunity > $100 ${monthName}-${year}`,
          accessor: `savings opportunity > $100 ${monthName.toLowerCase()}-${year}`,
        });
        columns.push({
          Header: `Savings Opportunity Tickets > $100 ${monthName}-${year}`,
          accessor: `savings opportunity tickets > $100 ${monthName.toLowerCase()}-${year}`,
        });
      });

      columns.push({
        Header: `6 Month Total Savings Opportunity > $100`,
        accessor: `6 month total savings opportunity > $100`,
      });
      columns.push({
        Header: `6 Month Total Savings Opportunity Tickets > $100`,
        accessor: `6 month total savings opportunity tickets > $100`,
      });

      setColumns(columns)
    } else if (selectedReport.value === 'Travel and Expense Summary') {
      setColumns(travelandexpensesummary_column_set)
    } else if ([
      'Top 50 TM Travel and Entertainment Spend',
      'Top 200 TM Travel and Entertainment Spend',
      'All TM Travel and Entertainment Spend',
    ].includes(selectedReport.value)) {
      setColumns(travelandentertainmentspend_column_set)
    } else if (selectedReport.value === 'Business and First Class Segments') {
      setColumns(businessandfirstclasssegments_column_set)
    } else if (selectedReport.value === 'Top 100 by Category') {
      setColumns(top100bycategory_column_set)
    } else if (selectedReport.value === 'Top 100 by Business Purpose') {
      setColumns(top100bybusinesspurpose_column_set)
    } else if (selectedReport.value === 'Summary by Org') {
      setColumns(summarybyorg_column_set)
    } else if (selectedReport.value === 'Non Compliance Summary') {
      setColumns(noncompliancesummary_column_set)
    } else if (selectedReport.value === 'Out of Policy') {
      setColumns(outofpolicy_column_set)
    } else if (selectedReport.value === 'Personal Daily Bookings') {
      setColumns(personaldailybookings_column_set)
    } else if (selectedReport.value === 'Three or More VIP Travellers on same flight') {
      setColumns(threeormoreviptravellersonsameflight_column_set)
    } else if (selectedReport.value === 'VIP Report') {
      setColumns(vipreport_column_set)
    } else if (selectedReport.value === 'OC Travelers Next 21 days (Air)') {
      setColumns(octravelersnext21daysair_column_set)
    } else if (selectedReport.value === 'OC Travelers Next 21 days (Hotel)') {
      setColumns(octravelersnext21dayshotel_column_set)
    } else if (selectedReport.value === 'Air Directional Market Report - 3mth') {
      setColumns(airdirectionalreport_column_set)
    } else if (selectedReport.value === 'Ticket Totals by Month') {
      setColumns(atickettotalsmonth_column_set)
    } else if (selectedReport.value === 'NCF Report Short vs. Long Trip') {
      setColumns(shortvslong_column_set)
    } else if (selectedReport.value === 'Car Chain Spend') {
      setColumns(car_chain_spd_column_set)
    } else if (selectedReport.value === 'Exchanges and Refunds') {
      setColumns(exchange_and_refund_column_set)
    } else if (selectedReport.value === 'Global Hotel Preference Reasons') {
      const columns = [
        { Header: 'Reason', accessor: 'reason' },
      ];
      const monthName = getMonthName(selectedMonth.value)
      columns.push(
        {
        Header: `${monthName} ${selectedYear.value} #Nights`,
        accessor: 'current_month_nights',
        },
        {
        Header: `${monthName} ${selectedYear.value} Avg Rate`,
        accessor: 'current_month_avg_rate',
        },
        {
        Header: `${monthName} ${selectedYear.value} Spend`,
        accessor: 'current_month_spend',
        },
        {
        Header: `Jan-${monthName} ${selectedYear.value} #Nights`,
        accessor: 'ytd_month_nights',
        },
        {
        Header: `Jan-${monthName} ${selectedYear.value} Avg Rate`,
        accessor: 'ytd_month_avg_rate',
        },
        {
        Header: `Jan-${monthName} ${selectedYear.value} Spend`,
        accessor: 'ytd_month_spend',
        },
      );
      setColumns(columns)
    } else if (selectedReport.value === 'Global Hotel Summary') {
      const columns = [
        { Header: 'Country', accessor: 'country' },
      ];
        const monthName = getMonthName(selectedMonth.value)
        columns.push(
          {
          Header: `${monthName} ${selectedYear.value} #Nights`,
          accessor: 'current_month_nights',
          },
          {
          Header: `${monthName} ${selectedYear.value} Spend`,
          accessor: 'current_month_spend',
          },
          {
          Header: `${monthName} ${selectedYear.value} Avg Rate`,
          accessor: 'current_month_avg_rate',
          },
          {
          Header: `Jan-${monthName} ${selectedYear.value} #Nights`,
          accessor: 'ytd_month_nights',
          },
          {
          Header: `Jan-${monthName} ${selectedYear.value} Spend`,
          accessor: 'ytd_month_spend',
          },
          {
          Header: `Jan-${monthName} ${selectedYear.value} Avg Rate`,
          accessor: 'ytd_month_avg_rate',
          },
        );
      setColumns(columns)
    } else if (selectedReport.value === 'Airline Spend') {
      setColumns(airline_spend_column_set)
    } else if (selectedReport.value === 'Advanced Purchase YOY') {
      const columns = [
        { Header: 'Travel Type', accessor: 'dom_int' },
        { Header: 'Miles', accessor: 'miles' },
        { Header: 'Adv Purch Days', accessor: 'adv_pur_days_cat' },
      ];
      const monthName = getMonthName(selectedMonth.value)
      columns.push(
        {
          Header: `${monthName} ${selectedYear.value} ATP`,
          accessor: 'current_month_atp',
        },
        {
          Header: `${monthName} ${selectedYear.value} Spend`,
          accessor: 'current_month_spend',
        },
        {
          Header: `${monthName} ${selectedYear.value} Tickets`,
          accessor: 'current_month_ticket',
        },
        {
          Header: `YTD ${selectedYear.value} ATP`,
          accessor: 'ytd_month_atp',
        },
        {
          Header: `YTD ${selectedYear.value} Spend`,
          accessor: 'ytd_month_spend',
        },
        {
          Header: `YTD ${selectedYear.value} Tickets`,
          accessor: 'ytd_month_ticket',
        },
        {
          Header: `${monthName} ${(selectedYear.value)-1} ATP`,
          accessor: 'past_current_month_atp',
        },
        {
          Header: `${monthName} ${(selectedYear.value)-1} Spend`,
          accessor: 'past_current_month_spend',
        },
        {
          Header: `${monthName} ${(selectedYear.value)-1} Tickets`,
          accessor: 'past_current_month_ticket',
        },
        {
          Header: `YTD ${(selectedYear.value)-1} ATP`,
          accessor: 'past_ytd_month_atp',
        },
        {
          Header: `YTD ${(selectedYear.value)-1} Spend`,
          accessor: 'past_ytd_month_spend',
        },
        {
          Header: `YTD ${(selectedYear.value)-1} Tickets`,
          accessor: 'past_ytd_month_ticket',
        },
        {
          Header: 'Monthly Variance ATP',
          accessor: 'monthly_variance_atp',
        },
        {
          Header: 'Monthly Variance Spend',
          accessor: 'monthly_variance_spend',
        },
        {
          Header: 'Monthly Variance Tickets',
          accessor: 'monthly_variance_ticket',
        },
        {
          Header: 'YTD Variance ATP',
          accessor: 'ytd_variance_atp',
        },
        {
          Header: 'YTD Variance Spend',
          accessor: 'ytd_variance_spend',
        },
        {
          Header: 'YTD Variance Tickets',
          accessor: 'ytd_variance_ticket',
        },
      )
      setColumns(columns)
    } else if (selectedReport.value === 'YOY ATP') {
      const columns = [
        { Header: 'Dom / Int', accessor: 'dom_int' },
        { Header: 'Advanced Purchase', accessor: 'adv_purchase_grouping' },
        { Header: 'Carrier Type', accessor: 'validating_carrier_cd' },
      ];
      columns.push(
        {
          Header: `${selectedYear.value} Net Tickets`,
          accessor: 'current_year_tickets',
        },
        {
          Header: `${selectedYear.value} % of Tickets`,
          accessor: 'current_year_tickets_perc',
        },
        {
          Header: `${selectedYear.value} ATP`,
          accessor: 'current_year_atp',
        },
        {
          Header: `${(selectedYear.value)-1} Net Tickets`,
          accessor: 'prev_year_tickets',
        },
        {
          Header: `${(selectedYear.value)-1} % of Tickets`,
          accessor: 'prev_year_tickets_perc',
        },
        {
          Header: `${(selectedYear.value)-1} ATP`,
          accessor: 'prev_year_atp',
        },
        {
          Header: 'Variance Net Tickets',
          accessor: 'variance_ticktes',
        },
        {
          Header: 'Variance % of Tickets',
          accessor: 'variance_ticktes_perc',
        },
        {
          Header: 'Variance ATP',
          accessor: 'variance_atp',
        },
      )
      setColumns(columns)
    } else if (selectedReport.value === 'Airline Contract Savings') {
      setColumns(airline_cont_saving_column_set)
    } else if (selectedReport.value === 'Hotel Property Report') {
      setColumns(hotel_prop_rpt_column_set)
    } else if (selectedReport.value === 'Transaction Summary Detail') {
      setColumns(trans_summary_column_set)
    } else if (selectedReport.value === 'Ernst And Young Reports Air') {
      setColumns(ey_air_column_set)
    } else if (selectedReport.value === 'Ernst And Young Reports Rail') {
      setColumns(ey_rail_column_set)
    } else if (selectedReport.value === 'Ernst And Young Reports Hotel') {
      setColumns(ey_hotel_column_set)
    } else if (selectedReport.value === 'Airline Optimization') {
      setColumns(airline_opt_column_set)
    } else if (selectedReport.value === 'Global Air Savings Opportunities') {
      const columns = [
        { Header: 'Fare Choice Reason', accessor: 'fare choice reason' },
      ];
      const monthName = getMonthName(selectedMonth.value)
      columns.push(
        {
        Header: `Tickets ${monthName}-${selectedYear.value}`,
        accessor: `tickets ${monthName.toLowerCase()}-${selectedYear.value}`,
        },
        {
        Header: `Net Amount ${monthName}-${selectedYear.value}`,
        accessor: `net amount ${monthName.toLowerCase()}-${selectedYear.value}`,
        },
        {
        Header: `Net Savings Rejected ${monthName}-${selectedYear.value}`,
        accessor: `net savings rejected ${monthName.toLowerCase()}-${selectedYear.value}`,
        },
        {
        Header: `Net Savings Accepted ${monthName}-${selectedYear.value}`,
        accessor: `net savings accepted ${monthName.toLowerCase()}-${selectedYear.value}`,
        },
        {
        Header: `Net Savings Identified ${monthName}-${selectedYear.value}`,
        accessor: `net savings identified ${monthName.toLowerCase()}-${selectedYear.value}`,
        },
        {
        Header: `YTD Tickets`,
        accessor: `ytd tickets`,
        },
        {
        Header: `YTD Net Amount`,
        accessor: `ytd net amount`,
        },
        {
        Header: `YTD Net Savings Rejected`,
        accessor: `ytd net savings rejected`,
        },
        {
        Header: `YTD Net Savings Accepted`,
        accessor: `ytd net savings accepted`,
        },
        {
        Header: `YTD Net Savings Identified`,
        accessor: `ytd net savings identified`,
        },
      );
      setColumns(columns)
    } else if (selectedReport.value === 'Hotel Chain Spend') {
      setColumns(hotel_chain_spend_column_set)
    } else if (selectedReport.value === 'Global Transaction Summary') {
      setColumns(glbl_trx_summary_column_set)
    } else if (selectedReport.value === 'Segment Mileage') {
      setColumns(segment_mil_column_set)
    } else if (selectedReport.value === 'Top Traveler with Segments and Miles Quarterly') {
      setColumns(top_trvl_seg_mil_qtr_column_set)
    } else if (selectedReport.value === 'Top Traveler with Segments and Miles Yearly') {
      setColumns(top_trvl_seg_mil_yrly_column_set)
    } else if (selectedReport.value === 'Global Air Spend Summary') {
      setColumns(glbl_air_spd_sumry_column_set)
    } else if (selectedReport.value === 'Top 10 Non-Directional Markets by Spend') {
      const columns = [
        { Header: 'Top 10 Markets', accessor: 'non_directional_market_pair' },
      ];
        const monthName = getMonthName(selectedMonth.value)
        const prevMonthName = getMonthName(selectedMonth.value-1)
        columns.push(
          {
          Header: `${prevMonthName} ${selectedYear.value} Spend`,
          accessor: 'prev_month_spend'
          },
          {
          Header: `${prevMonthName} ${selectedYear.value} #Tickets`,
          accessor: 'prev_month_ticket'
          },
          {
          Header: `${monthName} ${selectedYear.value} Spend`,
          accessor: 'current_month_spend'
          },
          {
          Header: `${monthName} ${selectedYear.value} #Tickets`,
          accessor: 'current_month_ticket'
          },
          { Header: 'Spend Variance', accessor: 'spend_variance' },
          { Header: 'Spend Variance %', accessor: 'perc_spend_variance' },
          { Header: '#Ticket Variance', accessor: 'ticket_variance' },
          { Header: '#Ticket Variance %', accessor: 'perc_ticket_variance' },
          {
          Header: `% Total for ${monthName} Spend`,
          accessor: 'perc_total_current_month_spend'
          },
          {
          Header: `% Total for ${monthName} #Tickets`,
          accessor: 'perc_total_current_month_ticket'
          },
        );
      setColumns(columns)
    }
  }

  // const downloadCsv = async () => {
  //   const response = await fetch(`${config.WELLS_FARGO_BASE_URL}reporting-hub/download-csv?report_name=${selectedReport.value}&lob_name=${selectedLob.value}&year=${selectedYear.value}&month=${selectedMonth.value}`, {
  //     headers: {
  //       Authorization: auth.authorization,
  //       Authentication: auth.authentication,
  //       nonce: auth.nonce,
  //     }
  //   });
  //   const blob = await response.blob();

  //   // Create a link element and click it to trigger the download
  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(new Blob([blob]));
  //   link.download = `${selectedReport.value}.zip`;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const downloadCsv = async () => {
    // Fetch the streaming response

    setDownloadStatus('preparing');
    setCursor('cursor-progress');

    regions.forEach((region) => {
        region.children.forEach((country) => {
            if (region.checked === true || country.checked === true) {
                selectedCountries.push(country.value.toUpperCase())
            }   
        })
    })

    let url = ''
    if (selectedReportCategory.value === 'LOB') {
      if (!['Top 100 by Category', 'Top 100 by Business Purpose'].includes(selectedReport.value)) {
        url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/download-csv?report_name=${selectedReport.value}&lob_name=${selectedLob.value}&business_purpose=${businessPurposeFilter}&travel_type=${travelTypeFilter}&countries=${selectedCountries}&year=${selectedYear.value}&month=${selectedMonth.value}`
      } else {
        url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/download-csv?report_name=${selectedReport.value}&lob_name=${selectedLob.value}&countries=${selectedCountries}&year=${selectedYear.value}&month=${selectedMonth.value}`
      }
      // url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/download-csv?report_name=${selectedReport.value}&lob_name=${selectedLob.value}&business_purpose=${businessPurposeFilter.map(function(x: any){ return x.value; })}&travel_type=${travelTypeFilter}&countries=${selectedCountries}&year=${selectedYear.value}&month=${selectedMonth.value}`
    } else if (selectedReportCategory.value === 'LOB-Org Unit Compare') {
      url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/download-csv?report_name=${selectedReport.value}&lob_name=${selectedLob.value}&countries=${selectedCountries}&year=${selectedYear.value}&month=${selectedMonth.value}`
    } else if (selectedReportCategory.value === 'Post Trip') {
        if (['Global Hotel Preference Reasons','Global Hotel Summary'].includes(selectedReport.value)) {
          url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/download-csv?report_name=${selectedReport.value}&travel_type=${travelTypeFilter}&year=${selectedYear.value}&month=${selectedMonth.value}`
        } else if (['Ernst And Young Reports Air','Ernst And Young Reports Rail','Ernst And Young Reports Hotel', 'Global Air Spend Summary'].includes(selectedReport.value)) {
          url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/download-csv?report_name=${selectedReport.value}&year=${selectedYear.value}&month=${selectedMonth.value}`
        } else if (['Segment Mileage'].includes(selectedReport.value)) {
          url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/download-csv?report_name=${selectedReport.value}&countries=${selectedCountries}&year=${selectedYear.value}&month=${selectedMonth.value}`
        } else {
          url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/download-csv?report_name=${selectedReport.value}&travel_type=${travelTypeFilter}&countries=${selectedCountries}&year=${selectedYear.value}&month=${selectedMonth.value}`
        }
    } else {
      url = `${config.WELLS_FARGO_BASE_URL}reporting-hub/download-csv?report_name=${selectedReport.value}&countries=${selectedCountries}&date=${selectedDate}`
    }
  
    const fileStream = StreamSaver.createWriteStream(`${selectedReport.value}.zip`)

    fetch(url, {
      headers: {
        apikey: config.APIKEY,
        Authorization: auth.authorization,
        Authentication: auth.authentication,
        nonce: auth.nonce,
      }
    }).then(res => {
      setDownloadStatus('progress');
      const readableStream = res.body

      // more optimized
      if (window.WritableStream && readableStream.pipeTo) {
        return readableStream.pipeTo(fileStream)
          .then(() => {
            console.log('done writing')
            setDownloadStatus('success');
            setCursor('cursor-default');
          })
      }

      window.writer = fileStream.getWriter()

      const reader = res.body.getReader()
      const pump = () => reader.read()
        .then(res => res.done
          ? writer.close()
          : writer.write(res.value).then(pump))

      pump()
    }).catch(error => {
      setDownloadStatus('failed');
      setCursor('cursor-default');
    })
  };

  return (
    <div className={`flex h-[90%] justify-between pt-12 ${cursor}`}>
      <div className='relative bg-white border rounded border-light-gray drop-shadow-performanceBox md:max-w-[35%] h-full py-6 pl-4 pr-6 mr-6 overflow-visible flex flex-col'>
          {isPostTripInfoLoading && (
              <>
                <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20'>
                  <div className="w-10 h-10 rounded-full animate-spin border-4 border-solid border-blue border-t-transparent"></div>
                </div>
                <div className="absolute inset-0 bg-gray-500 opacity-50 z-20"></div>
              </>
          )}
          <Scrollbar style={{ marginLeft: '0.5rem', maxHeight: `${selectedReportCategory.value === 'LOB' ? '560px' : '443px'}` }}>
          <p className="text-base font-bold text-blue">
            Create a New Report
          </p>
          <div className="mt-4">
              {/* Filter by BU and Level */}
              <label id='Report-Label' className='text-sm font-bold text-gray-9'>Select Report Category</label>
              <Select
                  aria-labelledby='Report-Label'
                  value={selectedReportCategory}
                  options={reportCategories}
                  menuPlacement= 'auto'
                  onChange={(selectedOption) => setSelectedReportCategory(selectedOption)}
                  className="rounded-lg w-[328px] mt-1 text-sm"
                  styles={{
                      indicatorSeparator: () => ({ display: "none" }),
                  }}
              />
          </div>
          {['LOB', 'LOB-Org Unit Compare'].includes(selectedReportCategory.value) && (
            <>
              <div className="mt-4">
                {/* Filter by BU and Level */}
                <label id='LOB-Label' className='text-sm font-bold text-gray-9'>LOB</label>
                <Select
                    aria-labelledby='LOB-Label'
                    value={selectedLob}
                    options={customItems}
                    menuPlacement= 'auto'
                    onChange={(selectedOption) => setSelectedLob(selectedOption)}
                    className="rounded-lg w-[328px] mt-1 text-sm"
                    styles={{
                        indicatorSeparator: () => ({ display: "none" }),
                    }}
                />
              </div>
            </>
          )}
          {!(['Top 100 by Category', 'Top 100 by Business Purpose', 'Summary by Org', 'Non Compliance Summary'].includes(selectedReport.value)|| ['Post Trip'].includes(selectedReportCategory.value)) && (
            <>
              <div className="mt-4">
                  {/* Filter for Business Purpose */}
                  <label id='Business-Label' className='text-sm font-bold text-gray-9'>Business Purpose</label>
                  <Select
                      aria-labelledby='Business-Label'
                      value={{value: businessPurposeFilter, label: businessPurposeFilter}}
                      options={businessPurposeFilters}
                      menuPlacement= 'auto'
                      onChange={(selectedOption) => setBusinessPurposeFilter(selectedOption.value)}
                      className="rounded-lg w-[328px] mt-1 text-sm"
                      styles={{
                          indicatorSeparator: () => ({ display: "none" }),
                      }}
                  />
                  {/* <Select
                      options={businessPurposeFilters}
                      isMulti
                      closeMenuOnSelect={false}
                      isClearable={false}
                      menuPlacement= 'auto'
                      hideSelectedOptions={false}
                      components={{
                          Option: InputOption
                      }}
                      onChange={(selectedOption) => setBusinessPurposeFilter(selectedOption)}
                      allowSelectAll={true}
                      value={businessPurposeFilter}
                      className="rounded-lg w-[328px] mt-1 text-sm"
                      styles={{
                          indicatorSeparator: () => ({ display: "none" }),
                          option: (base, { data, isDisabled, isFocused, isSelected }) => {
                              return {
                                ...base,
                                backgroundColor: isSelected && "white",
                                color: isSelected && "black",
                              };
                          }
                      }}
                  /> */}
              </div>
            </>
          )}
          {!['Top 100 by Category', 'Top 100 by Business Purpose', 'Summary by Org', 'Non Compliance Summary','Ernst And Young Reports Air','Ernst And Young Reports Rail','Ernst And Young Reports Hotel', 'Segment Mileage','Global Air Spend Summary'].includes(selectedReport.value) && (
            <>
              <div className="mt-4">
                  {/* Filter for Travel Type */}
                  <label id='Travel-Label' className='text-sm font-bold text-gray-9'>Domestic / International</label>
                  <Select
                      aria-labelledby='Travel-Label'
                      value={{value: travelTypeFilter, label: travelTypeFilter}}
                      options={travelTypeFilters}
                      menuPlacement= 'auto'
                      onChange={(selectedOption) => setTravelTypeFilter(selectedOption.value)}
                      className="rounded-lg w-[328px] mt-1 text-sm"
                      styles={{
                          indicatorSeparator: () => ({ display: "none" }),
                      }}
                  />
              </div>
            </>
          )}
          {!['Global Hotel Preference Reasons','Global Hotel Summary','Ernst And Young Reports Air','Ernst And Young Reports Rail','Ernst And Young Reports Hotel','Global Air Spend Summary'].includes(selectedReport.value) && (
            <CountryFilter userAuthInfo={userAuthInfo} regions={regions} setRegions={setRegions} />
          )}
          {['LOB', 'LOB-Org Unit Compare','Post Trip'].includes(selectedReportCategory.value) && (
            <>
              <div className="mt-4">
                  {/* Filter for select year */}
                  <label id='Year-Label' className='text-sm font-bold text-gray-9'>Year</label>
                  <Select
                      aria-labelledby='Year-Label'
                      value={selectedYear}
                      options={yearOptions}
                      menuPlacement= 'auto'
                      onChange={(selectedOption) => setSelectedYear(selectedOption)}
                      className="rounded-lg w-[328px] mt-1 text-sm"
                      styles={{
                          indicatorSeparator: () => ({ display: "none" }),
                      }}
                  />
              </div>
              <div className={`mt-4 ${selectedReportCategory.value === 'LOB' && 'mb-2'}`}>
                  {/* Filter for select month */}
                  <label id='Month-Label' className='text-sm font-bold text-gray-9'>Month</label>
                  <Select
                      aria-labelledby='Month-Label'
                      value={selectedMonth}
                      options={monthOptions}
                      menuPlacement= {`${['Ernst And Young Reports Air','Ernst And Young Reports Rail','Ernst And Young Reports Hotel', 'Global Air Spend Summary'].includes(selectedReport.value) ? 'bottom' : 'top'}`}
                      maxMenuHeight='250px'
                      onChange={(selectedOption) => setSelectedMonth(selectedOption)}
                      className="rounded-lg w-[328px] mt-1 text-sm"
                      styles={{
                          indicatorSeparator: () => ({ display: "none" }),
                      }}
                  />
              </div>
            </>
          )}
          {!['LOB', 'LOB-Org Unit Compare','Post Trip'].includes(selectedReportCategory.value) && (
            <div className="mt-4" ref={outsideClickref}>
              <label className='text-sm font-bold text-gray-9'>Date</label>
              <button
                onClick={handleClick}
                className="flex flex-row items-center w-[328px] border border-light-gray drop-shadow-options rounded-[0.25rem] p-2 text-sm"
              >
                <img src={CalendarIcon} alt="Options" />

                <span className="ml-1">
                  {selectedDate ? 
                    `${format(
                        parse(selectedDate, 'yyyy-MM-dd', new Date()),
                        'dd-MMM-yyyy'
                    )}`
                    : `Select date`
                  }
                </span>
              </button>
              <div
                className={`${
                  open ? 'block z-40 absolute' : 'hidden'
                } flex flex-row items-center justify-center w-[328px] bg-white mt-2 border-[1.5px] border-gray rounded-md`}
              >
                <DatePickerSingle selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
              </div>
            </div>
          )}
          {/* <div className="mt-4">
              <label className='text-sm font-bold text-gray-9'>Week</label>
              <Select
                  value={weeks[0]}
                  options={weeks}
                  onChange={(selectedOption) => setWeeks(selectedOption)}
                  className="rounded-lg w-[328px] mt-1 text-sm"
                  styles={{
                      indicatorSeparator: () => ({ display: "none" }),
                  }}
              />
          </div> */}
        </Scrollbar>
        <div className='px-2'>
          <div className="mt-4">
              {/* Filter by BU and Level */}
              <label id='Report-label' className='text-base font-bold text-blue'>List of Reports</label>
              <Select
                  aria-labelledby='Report-label'
                  value={selectedReport}
                  options={selectedReports}
                  menuPlacement= 'top'
                  onChange={(selectedOption) => setSelectedReport(selectedOption)}
                  className="rounded-lg w-[328px] mt-1 text-sm"
                  styles={{
                      indicatorSeparator: () => ({ display: "none" }),
                  }}
              />
          </div>
        </div>
        <div className='flex items-end h-[7.5%] mt-auto px-2'>
          <button onClick={previewReport} className={`font-semibold text-white border border-light-blue rounded-lg text-sm bg-blue ${loading ? 'bg-light-blue' : 'bg-blue'} hover:opacity-90 py-2 px-4`} disabled={loading}>Preview Report</button>
        </div>
      </div>
      <div className='relative bg-white border rounded border-light-gray drop-shadow-performanceBox h-full flex-1 p-6 overflow-hidden'>
        <div className="h-[5%] text-base font-bold text-blue flex items-start">
          Report Preview
        </div>
        <div className='relative drop-shadow-performanceBox overflow-hidden border border-light-gray rounded h-[87.5%] bg-white'>
          {loading && (
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
              <div className="w-10 h-10 rounded-full animate-spin border-4 border-solid border-blue border-t-transparent"></div>
            </div>
          )}
          {loading && <div className="absolute inset-0 bg-gray-500 opacity-50 z-20"></div>}
          <div className='h-[7.5%] flex items-center px-6'>
            <div>
              <label htmlFor="pageSize">Show: </label>
              <select id="pageSize" className='border-transparent focus:border-transparent focus:ring-0 bg-transparent' value={pageSize} onChange={handlePageSizeChange}>
                {[50, 100, 250, 500].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='h-[85%] overflow-auto'>
          <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100 sticky top-0 z-10">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} className='px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider'>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className='bg-white divide-y divide-gray-200'>
              {rows.length === 0 ? (
                <tr>
                  <td colSpan={columns.length} className="text-sm text-gray-500 font-bold absolute left-[50%] translate-x-[-50%]">
                    No records to display.
                  </td>
                </tr>
              ) : (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()} className='px-6 py-4 text-sm whitespace-nowrap'>
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          </div>
          <div className='h-[7.5%] flex justify-between items-center px-6'>
            <button className='border border-gray-500 rounded-lg w-[85px] py-1' onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </button>{' '}
            <div>
              {pageOptions.map((page) => {
                const isActive = page === pageIndex;
                if (page === 0 || page === totalCount - 1 || (page >= pageIndex - 2 && page <= pageIndex + 2)) {
                  return (
                    <button key={page} onClick={() => gotoPage(page)} disabled={pageIndex === page} className={isActive ? 'border border-gray-500 rounded px-2' : ' px-2'}>
                      {page + 1}
                    </button>
                  );
                } else if (page === 1 || page === totalCount - 2) {
                  // Display dots for adjacent pages
                  return <span key={page}>...</span>;
                }
                return null;
              })}
            </div>
            <button className='border border-gray-500 rounded-lg w-[85px] py-1' onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </button>{' '}
          </div>
        </div>
        <div className='flex items-end h-[7.5%]'>
          <button onClick={downloadCsv} className={`font-semibold text-white border border-light-blue rounded-lg text-sm ${downloadStatus === 'preparing' ? 'bg-light-blue' : 'bg-blue'} hover:opacity-90 py-2 px-4`} disabled={downloadStatus === 'preparing'}>Download Report</button>
          {(downloadStatus === 'preparing') ? (
            <div className='prepare flex items-end mb-2'>
              <div className="w-6 h-6 rounded-full animate-spin border-4 border-solid border-green-500 border-t-transparent ml-3 mr-2"></div>
              <p className='text-green-500'>Preparing to Download</p>
            </div>
          ) : (downloadStatus === 'progress') ? (
            <div className='prepare flex items-end mb-2'>
              <p className='text-[#ffc107] font-semibold ml-2 mr-1'>Download in Progress</p>
              <div class='h-1 w-1 mr-[0.125rem] bg-[#ffc107] rounded-full animate-bounce [animation-delay:-0.3s] mb-1'></div>
              <div class='h-1 w-1 mr-[0.125rem] bg-[#ffc107] rounded-full animate-bounce [animation-delay:-0.15s] mb-1'></div>
              <div class='h-1 w-1 bg-[#ffc107] rounded-full animate-bounce mb-1'></div>
            </div>
          ) : (downloadStatus === 'success') ? (
            <div className='prepare flex items-end mb-2'>
              <p className='text-green-500 font-semibold ml-2'>Download Completed!</p>
            </div>
          ) : (downloadStatus === 'failed') ? (
            <div className='prepare flex items-end mb-2'>
              <p className='text-red-500 font-semibold ml-2'>Download Failed!</p>
            </div>
          ): (
            <p></p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportingHubTable;